import React, { useState, createRef, useEffect, useCallback } from "react";
import { Fade, Grid } from "@mui/material";
import { Alert, Box, Input, styles, InventoryTable } from "../Components";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import { Auth } from "aws-amplify";
import _ from "lodash";

const Inventory = () => {
  const [alertState, setAlertState] = useState({ shouldShow: false });
  const [isLoading, setLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providers, setProviders] = useState([]);
  const [devices, setDevices] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredDevices, setFiltered] = useState([]);
  const [selectedNewProvider, setSelectedNewProvider] = useState("");
  const psnRef = createRef();

  const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";
  const classes = styles();

  const handleAlertClose = (event, reason) => {
    setAlertState({ shouldShow: false });
  };

  const fetchListOfProviders = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(baseUrl + "/provider", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    setProviders(json.body);
    setLoading(false);
  };

  useEffect(() => {
    fetchListOfProviders();
  }, []);

  const fetchListOfPatches = async (shouldLoad) => {
    if (shouldLoad) {
      setLoading(true);
    }
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/inventory/patch?upid=${selectedProvider.UPID}&detailed=True`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    setDevices(json.body);
    setFiltered(json.body);
    console.log(json.body);
    if (shouldLoad) setLoading(false);
  };

  useEffect(() => {
    fetchListOfPatches(true);
  }, [selectedProvider]);

  const handleSelectedProvider = (event) => {
    setDevices([]);
    setSelectedProvider(event.target.value);
  };

  const handleDeviceIdInput = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
    debounceFn(event.target.value);
  };

  const handleScan = (value) => {
    if (!value.includes(";")) {
      setSearch("");
      updateSearchFilter();
      return;
    }
    const psn_array = value.split(";");
    let psn = "";

    for (var i in psn_array) {
      const part = psn_array[i].split(":");
      if (i === "2") {
        psn = psn.concat(part[1]);
      } else {
        psn = psn.concat(part[1], "_");
      }
    }
    setSearch(psn);
    updateSearchFilter();
  };

  const updateDeviceProvider = async (udid) => {
    if (!udid) {
      return;
    }
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/operator/patch?deviceid=${udid}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          newprovider: selectedNewProvider.ProvID,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: `Patch ${udid} set to provider ${selectedProvider.ProvID}`,
          sev: "success",
          shouldShow: true,
        });
      } else {
        setAlertState({
          message: json.body[0].Message,
          sev: "warning",
          shouldShow: true,
        });
      }
    }
    fetchListOfPatches(false);
  };

  const debounceFn = useCallback(_.debounce(handleScan, 200), []);

  const updateSearchFilter = () => {
    if (!search) {
      setFiltered(devices);
    } else {
      let filtered = devices.filter((value) => {
        return value?.ProdSerNum.includes(search);
      });
      console.log(filtered);
      setFiltered(filtered);
    }
  };

  useEffect(() => {
    updateSearchFilter();
  }, [search]);

  return (
    <>
      <Snackbar
        open={alertState.shouldShow}
        autoHideDuration={1000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAlertClose} severity={alertState.sev}>
          {alertState.message}
        </Alert>
      </Snackbar>
      <Fade in={true} mountOnEnter unmountOnExit>
        <div>
          <Grid container justifyContent="center" alignItems="center">
            <Box>
              <Input
                isLoading={providers.length === 0}
                select
                label="Old Provider"
                value={selectedProvider}
                onChange={handleSelectedProvider}
              >
                {providers.map((option, index) => {
                  return (
                    <MenuItem
                      key={`dropdown-${index}-${option}`}
                      value={option}
                    >
                      {`${option.Name} (${option.ProvID})`}
                    </MenuItem>
                  );
                })}
              </Input>
            </Box>
            <Box>
              <Input
                isLoading={providers.length === 0}
                select
                label="New Provider"
                value={selectedNewProvider}
                onChange={(event) => setSelectedNewProvider(event.target.value)}
              >
                {providers.map((option, index) => {
                  return (
                    <MenuItem
                      key={`dropdown-${index}-${option}`}
                      value={option}
                    >
                      {`${option.Name} (${option.ProvID})`}
                    </MenuItem>
                  );
                })}
              </Input>
            </Box>
            <Box>
              <Input
                label="Search"
                value={search}
                onChange={(event) => handleDeviceIdInput(event)}
              />
            </Box>
            <Box sx={{ width: "70vw" }}>
              <Fade in={!!providers}>
                <div>
                  <InventoryTable
                    selectedProvider={selectedProvider.UPID}
                    devices={filteredDevices}
                    isLoading={isLoading}
                    updateProvider={(deviceID) =>
                      updateDeviceProvider(deviceID)
                    }
                  />
                </div>
              </Fade>
            </Box>
          </Grid>
        </div>
      </Fade>
    </>
  );
};

export default Inventory;
