import React, { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import {
  Button,
  styles,
  Box,
  Input,
  Alert,
  TimeZoneSelect,
} from "../Components";

import {
  Grid,
  Paper,
  Step,
  StepLabel,
  CircularProgress,
  Typography,
  Fade,
  Snackbar,
  Stepper as MyStepper,
} from "@mui/material";

const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";
const steps = ["Create Provider", "Invite Admin"];

const Provider = () => {
  const [activeStep, setStep] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [uuid, setUUID] = useState("");
  const [provider, setProviderId] = useState(0);
  const [name, setName] = useState("");
  const [address, setAddress] = useState({});
  const [TimeZone, setTimezone] = useState("America/Los_Angeles");
  const [alertState, setAlertState] = useState({ shouldShow: false });
  const [NewEmail, setNewEmail] = useState("");
  const [First, setFirst] = useState("");
  const [Last, setLast] = useState("");

  const classes = styles();
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async (event) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0) {
      console.log("Creating new provider");
      await handleNewProvider(event);
    } else if (activeStep === 1) {
      setAlertState({ shouldShow: false });
      console.log("Creating new user");
      SignUpUser();
    } else {
      setStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setStep(0);
  };

  const useEffectOnlyFirst = (fn, arr) => {
    const isFirst = useRef(false);

    useEffect(() => {
      if (isFirst.current) {
        return;
      } else {
        isFirst.current = true;
        return fn();
      }
    }, arr);
  };
  const handleNewProvider = async (event) => {
    setLoading(true);
    if (!!event) {
      event.preventDefault();
    }
    const user = await Auth.currentAuthenticatedUser();
    const upload = await fetch(baseUrl + "/admin/superuser/provider", {
      method: "POST",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      }),
      cache: "no-cache",
      body: JSON.stringify({
        provider: provider,
        uuid: uuid,
        address: address,
        timezone: !TimeZone ? "America/Los_Angeles" : TimeZone,
        name: name,
      }),
    });
    const json = await upload.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else if ("Message" in json.body[0]) {
      console.log(json.body[0]);
      setAlertState({
        message: json.body[0].Message,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: "Provider Successfully Created!",
          sev: "success",
          shouldShow: true,
        });
        setStep((previousStep) => previousStep + 1);
      }
    }

    setLoading(false);
  };

  useEffectOnlyFirst(() => {
    fetchUUID();
  }, [uuid]);

  useEffectOnlyFirst(() => {
    fetchProvId();
  }, [provider]);

  const fetchUUID = async () => {
    setLoading(true);
    setUUID("");
    const user = await Auth.currentAuthenticatedUser();
    const uuid_result = await fetch(
      baseUrl + "/admin/superuser/uuid?type=Device",
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await uuid_result.json();
    setUUID(json.body[0].uuid[0]);
    setLoading(false);
  };

  const fetchProvId = async () => {
    setProviderId(0);
    const user = await Auth.currentAuthenticatedUser();
    const provId_result = await fetch(baseUrl + "/operator/next-id", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await provId_result.json();
    if (!json?.body[0]?.WasSuccess) {
      setAlertState({
        shouldShow: true,
        sev: "error",
        message: "Out of provider Ids",
      });
    } else {
      setProviderId(json.body[0].ProvId);
    }
  };

  const SignUpUser = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const upload = await fetch(baseUrl + "/operator/user/create", {
      method: "PUT",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
      }),
      cache: "no-cache",
      body: JSON.stringify({
        Email: NewEmail,
        First: First,
        Last: Last,
        Provider: provider,

        TimeZone: TimeZone,
      }),
    });
    const json = await upload.json();
    if (json?.body[0]?.WasSuccess) {
      setAlertState({
        message: json?.body[0]?.Message,
        sev: "success",
        shouldShow: true,
      });
    } else if ("Message" in json?.body[0]) {
      setAlertState({
        message: json?.body[0]?.Message,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
    }

    setLoading(false);
  };

  const handleAlertClose = (event, reason) => {
    setAlertState({ shouldShow: false });
  };

  const handleState = (event) => {
    const newState = event.target.value;
    if (event.target.value.length > 2) {
      event.preventDefault();
    } else {
      setAddress({ ...address, state: newState });
    }
  };

  const handleZip = (event) => {
    const newZip = event.target.value;
    if (event.target.value.length > 5) {
      event.preventDefault();
    } else {
      setAddress({ ...address, zip: newZip });
    }
  };

  return (
    <>
      <Grid container spacing={0} style={{ minWidth: "90vw" }}>
        <Box sx={{ width: "80vw" }}>
          <Grid container justifyContent="center" alignContent="center">
            <Fade in={alertState.shouldShow}>
              <div stlye={{ width: "80vw", height: "32px" }}>
                <Alert onClose={handleAlertClose} severity={alertState.sev}>
                  {alertState.message}
                </Alert>
              </div>
            </Fade>
            <Paper
              elevation={5}
              sx={{ width: "80vw", height: "80vh", position: "relative" }}
            >
              <Box>
                <MyStepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </MyStepper>
              </Box>
              {/* Card Content */}
              {activeStep === 0 && (
                <>
                  <form noValidate autoComplete="off">
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Box>
                        <Typography variant="h3">Add Provider</Typography>
                      </Box>
                      <Grid container item justifyContent="center">
                        <Box>
                          <Input
                            className={classes.textField}
                            id="name-input"
                            label="Provider Name"
                            value={name}
                            readOnly
                            onChange={(event) => setName(event.target.value)}
                          />
                        </Box>
                        <Box>
                          <Input
                            className={classes.textField}
                            id="provider-input"
                            label="Provider ID"
                            value={provider}
                            disabled
                            error={provider < 0}
                            onChange={(event) => event.preventDefault()}
                            required
                          />
                        </Box>
                      </Grid>

                      <Grid
                        container
                        item
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box>
                          <Input
                            className={classes.textField}
                            id="address-input"
                            label="Address"
                            value={address.first}
                            readOnly
                            onChange={(event) =>
                              setAddress({
                                ...address,
                                first: event.target.value,
                              })
                            }
                          />
                        </Box>
                        <Box>
                          <Input
                            className={classes.textField}
                            id="city-input"
                            label="City"
                            value={address.city}
                            readOnly
                            onChange={(event) =>
                              setAddress({
                                ...address,
                                city: event.target.value,
                              })
                            }
                          />
                        </Box>
                        <Box>
                          <Input
                            className={classes.smallTextField}
                            id="state-input"
                            label="State"
                            value={address.state}
                            readOnly
                            onChange={(event) => handleState(event)}
                          />
                        </Box>
                        <Box>
                          <Input
                            className={classes.smallTextField}
                            id="Zip-input"
                            label="Zip"
                            type="number"
                            value={address.zip}
                            readOnly
                            onChange={(event) => handleZip(event)}
                          />
                        </Box>
                        <TimeZoneSelect
                          onSelect={(newTZ) => setTimezone(newTZ)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Box sx={{ width: 100, height: 100 }}>
                        {isLoading && (
                          <Box>
                            <CircularProgress size={100} />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </form>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <form noValidate autoComplete="off">
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Box sx={{ p: 2 }}>
                        <Typography variant="h3">Invite User</Typography>
                      </Box>
                      <Grid container item justifyContent="center">
                        <Box>
                          <Input
                            id="input-first"
                            label="First Name"
                            value={First}
                            onChange={(event) => setFirst(event.target.value)}
                          />
                        </Box>
                        <Box>
                          <Input
                            id="input-Last"
                            label="Last Name"
                            value={Last}
                            onChange={(event) => setLast(event.target.value)}
                          />
                        </Box>

                        <Box>
                          <Input
                            id="input-email"
                            label="email"
                            value={NewEmail}
                            onChange={(event) =>
                              setNewEmail(event.target.value)
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid container item justifyContent="center">
                        <Box>
                          <Input
                            id="input-Provider"
                            label="Provider"
                            error={provider < 0}
                            type="number"
                            value={provider}
                            disabled
                          />
                        </Box>
                        <TimeZoneSelect
                          onSelect={(newTZ) => setTimezone(newTZ)}
                        />
                      </Grid>
                    </Grid>
                    <Box />
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Box sx={{ width: 100, height: 100 }}>
                        {isLoading && (
                          <Box>
                            <CircularProgress size={100} />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </form>
                </>
              )}

              {/* Buttons */}

              <>
                <Grid container justifyContent="center" alignContent="center">
                  <Grid item xs={3}>
                    <Box>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        {activeStep === 0 ? "Clear" : "Back"}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    {isStepOptional(activeStep) && (
                      <Box>
                        <Button color="inherit" onClick={handleSkip}>
                          {activeStep === steps.length - 1 ? "Skip" : "Next"}
                        </Button>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Button
                        onClick={() => handleNext()}
                        disabled={
                          activeStep === 1 &&
                          (!First || !Last || !NewEmail || !TimeZone)
                        }
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </>
            </Paper>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default Provider;
