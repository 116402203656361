import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import Home from "./Pages/home";
import custom_theme from "./Components/theme";
import { ThemeProvider } from "@mui/material/styles";
import {
  AmplifyAuthenticator,
  AmplifySignOut,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

function App() {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  const handleAuthStateChange = (nextAuthState, authData) => {
    console.log("handleAuthStateChange", authData);
  };
  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, [authState]);
  return (
    <div className="App">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <ThemeProvider theme={custom_theme}>
        <AmplifyAuthenticator
          hidden
          handleAuthStateChange={(nextAuthState, authData) =>
            handleAuthStateChange(nextAuthState, authData)
          }
        />
        <Home authState={authState} />
      </ThemeProvider>
    </div>
  );
}

export default App;
