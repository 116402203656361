import { styles } from "/";
import React, { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Box from "../Components/box";
import { Auth } from "aws-amplify";

import { Drawer as MuiDrawer, Grid, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import InboxIcon from "@mui/icons-material/MoveToInbox";

import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import InventoryIcon from "@mui/icons-material/Inventory";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import HomeWorkIcon from "@mui/icons-material/HomeWork";

import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";

const tabs = ["Devices", "Gateway", "Provider", "Inventory"];

const ListItemLink = (props) => {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

const Drawer = ({ onSignOut }) => {
  const navigate = useNavigate();
  const classes = styles();

  const handleSignOut = async () => {
    const wasSuccess = await Auth.signOut();
    onSignOut();
    navigate("/login");
  };

  return (
    <MuiDrawer
      className={classes.drawer}
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      open
      anchor="left"
    >
      <div className={classes.Toolbar}>
        <Box>
          <Grid container alignContent="center" justifyContent="center">
            <Grid item xs={12}>
              <img src="../caremate_logo.png" height="32" width="100" />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">Partner</Typography>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Divider />
      <List>
        {tabs.map((text, index) => (
          <ListItemLink
            to={`/${text}`}
            key={`drawer-item-${text}`}
            primary={text}
            icon={
              index === 3 ? (
                <InventoryIcon />
              ) : index === 1 ? (
                <PhoneAndroidIcon />
              ) : index === 0 ? (
                <DeveloperBoardIcon />
              ) : (
                <HomeWorkIcon />
              )
            }
          />
        ))}

        {
          <>
            <Divider />
            <ListItem button key={"Sign Out"} onClick={() => handleSignOut()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Sign Out"}></ListItemText>
            </ListItem>
          </>
        }
      </List>
    </MuiDrawer>
  );
};

export default Drawer;
