import React, { useState, useEffect } from "react";
import Story from "./Storybook";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { Auth } from "aws-amplify";
import SignIn from "./signin";

import { Box, styles, PrivateRoute, Drawer } from "../Components";

import Gateway from "./gateway";
import Provider from "./provider";
import Inventory from "./inventory";
import Device from "./device";

const Home = ({ authState }) => {
  const classes = styles();
  const [isAuth, setAuth] = useState(false);

  const handleAuthComplete = (auth) => {
    setAuth(auth);
  };

  const updateSignIn = async () => {
    try {
      const user = await Auth.currentSession();
      setAuth(user.isValid());
    } catch (error) {
      setAuth(false);
    }
  };

  useEffect(async () => {
    updateSignIn();
  }, [authState]);

  const isLoggedIn = authState !== "signedIn";

  console.log("isAuth", isAuth);
  return (
    <Box sx={{ display: "flex" }}>
      <Router>
        {isAuth && (
          <Drawer
            onSignOut={() => {
              setAuth(false);
            }}
          />
        )}
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            p: 3,
          }}
        >
          <Routes>
            {!isAuth && (
              <Route path="*">
                <Navigate to="/login" />
              </Route>
            )}

            <Route exact path="/login">
              <SignIn auth={isAuth} onAuthComplete={updateSignIn} />
            </Route>

            {isAuth && (
              <>
                <PrivateRoute exact path="/" auth={isAuth}>
                  <Device />
                </PrivateRoute>

                <PrivateRoute auth={isAuth} path="/storybook">
                  <Story />
                </PrivateRoute>
                <PrivateRoute auth={isAuth} path="/dashboard">
                  <Device />
                </PrivateRoute>
                <PrivateRoute auth={isAuth} path="/Gateway">
                  <Gateway />
                </PrivateRoute>
                <PrivateRoute exact auth={isAuth} path="/Inventory">
                  <Inventory />
                </PrivateRoute>
                <PrivateRoute exact auth={isAuth} path="/Devices">
                  <Device />
                </PrivateRoute>
                <PrivateRoute exact auth={isAuth} path="/Provider">
                  <Provider />
                </PrivateRoute>
              </>
            )}
          </Routes>
        </Box>
      </Router>
    </Box>
  );
};

export default Home;
