import { Button as MaterialButton } from "@mui/material";

const Button = (props) => {
  return (
    <MaterialButton
      sx={{ width: 120, height: 40 }}
      variant="contained"
      color="primary"
      {...props}
    />
  );
};

export default Button;
