import React, { useCallback, useState, createRef, useEffect } from "react";
import { Fade, Typography, Grid } from "@mui/material";
import {
  Alert,
  Box,
  Button,
  Input,
  styles,
  InventoryTable,
} from "../Components";
import Snackbar from "@mui/material/Snackbar";
import Skeleton from "@mui/material/Skeleton";
import MenuItem from "@mui/material/MenuItem";
import { Auth } from "aws-amplify";
import Paper from "@mui/material/Paper";
import _ from "lodash";

const Device = () => {
  const [alertState, setAlertState] = useState({ shouldShow: false });
  const [isLoading, setLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providers, setProviders] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceId, setDeviceId] = useState("");

  const psnRef = createRef();

  const baseUrl = "https://pow9rz7r6d.execute-api.us-west-2.amazonaws.com/dev";
  const classes = styles();

  const handleAlertClose = (event, reason) => {
    focuspsn();
    setAlertState({ shouldShow: false });
  };

  const fetchListOfProviders = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(baseUrl + "/provider", {
      method: "GET",
      headers: new Headers({
        Authorization: user.signInUserSession.idToken.jwtToken,
      }),
      cache: "no-cache",
    });
    const json = await endpointResp.json();
    setProviders(json.body);
    setLoading(false);
  };

  useEffect(() => {
    fetchListOfProviders();
  }, []);

  const fetchListOfPatches = async (shouldLoad) => {
    if (shouldLoad) {
      setLoading(true);
    }
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/inventory/patch?upid=${selectedProvider.UPID}&detailed=true`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    const devices = json.body;
    const sorted_devices = devices.sort((a, b) => {
      return a?.ProdSerNum > b?.ProdSerNum ? -1 : 1;
    });
    setDevices(sorted_devices);
    console.log(sorted_devices);
    if (shouldLoad) setLoading(false);
  };

  useEffect(() => {
    fetchListOfPatches(true);
  }, [selectedProvider]);

  const updateDeviceProvider = async (udid) => {
    if (!udid) {
      return;
    }
    const user = await Auth.currentAuthenticatedUser();
    const endpointResp = await fetch(
      baseUrl + `/operator/patch?deviceid=${udid}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          newprovider: selectedProvider.ProvID,
        }),
        cache: "no-cache",
      }
    );
    const json = await endpointResp.json();
    if ("Error" in json) {
      console.log(json.Error);
      setAlertState({
        message: json.Error,
        sev: "error",
        shouldShow: true,
      });
    } else {
      console.log(json.body[0]);
      if (json.body[0].WasSuccess) {
        setAlertState({
          message: `Patch ${deviceId} set to provider ${selectedProvider.ProvID}`,
          sev: "success",
          shouldShow: true,
        });
      } else {
        setAlertState({
          message: json.body[0].Message,
          sev: "warning",
          shouldShow: true,
        });
      }
    }
    fetchListOfPatches(false);
  };

  const handleScan = (value) => {
    if (!value.includes(";")) {
      setDeviceId("");
      return;
    }
    const psn_array = value.split(";");
    let psn = "";

    for (var i in psn_array) {
      console.log(i);
      const part = psn_array[i].split(":");
      if (i === "2") {
        psn = psn.concat(part[1]);
      } else {
        psn = psn.concat(part[1], "_");
      }
    }
    setDeviceId(psn);
  };
  const handleProvision = async () => {
    await updateDeviceProvider(deviceId);
    // reload table
    setDeviceId("");
    focuspsn();
  };

  const focuspsn = () => {
    if (!!psnRef.current) {
      psnRef.current.focus();
    }
  };

  const handleDeviceIdInput = (event) => {
    event.preventDefault();
    setDeviceId(event.target.value);
    debounceFn(event.target.value);
  };

  const debounceFn = useCallback(_.debounce(handleScan, 100), []);

  const handleSelectedProvider = (event) => {
    setDevices([]);
    setSelectedProvider(event.target.value);
  };

  return (
    <>
      <Snackbar
        open={alertState.shouldShow}
        autoHideDuration={1000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAlertClose} severity={alertState.sev}>
          {alertState.message}
        </Alert>
      </Snackbar>
      <Fade in={true} mountOnEnter unmountOnExit>
        <div>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Box>
                <Input
                  isLoading={providers.length === 0}
                  select
                  label="Provider"
                  value={selectedProvider}
                  onChange={handleSelectedProvider}
                >
                  {providers.map((option, index) => {
                    return (
                      <MenuItem
                        key={`dropdown-${index}-${option}`}
                        value={option}
                      >
                        {`${option.Name} (${option.ProvID})`}
                      </MenuItem>
                    );
                  })}
                </Input>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Input
                  isLoading={providers.length === 0}
                  autoFocus
                  inputRef={psnRef}
                  placeholder="12:34:56"
                  value={deviceId}
                  label="DeviceID"
                  onChange={(event) => {
                    handleDeviceIdInput(event);
                  }}
                ></Input>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box>
                <Button
                  disabled={
                    providers.length === 0 || !selectedProvider || !deviceId
                  }
                  onClick={() => handleProvision()}
                >
                  Provision
                </Button>
              </Box>
            </Grid>
            <Box sx={{ width: "70vw" }}>
              <Grid item>
                <InventoryTable
                  selectedProvider={selectedProvider.UPID}
                  devices={devices}
                  isLoading={isLoading}
                />
              </Grid>
            </Box>
          </Grid>
        </div>
      </Fade>
    </>
  );
};

export default Device;
