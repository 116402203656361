import { Typography, Paper, Grid } from "@mui/material";
import Button from "../Components/button";
import Input from "../Components/input";
import Box from "../Components/box";
import React from "react";

const Story = () => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box>
        <Paper sx={{ width: 200, height: 150 }}>
          <Typography>Button</Typography>
          <Button>Click me</Button>
        </Paper>
      </Box>
      <Box>
        <Paper sx={{ width: 300, height: 150 }}>
          <Typography>Input</Typography>
          <Input label={"Input"} />
        </Paper>
      </Box>
    </Grid>
  );
};

export default Story;
