import { makeStyles } from "@mui/styles";

const drawerWidth = 240;
const styles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  DrawerMargin: {
    marginLeft: drawerWidth,
    marginTop: 25,
  },
  menuButton: {
    marginRight: 8,
  },
  button: {
    margin: 4,
  },
  chip: {
    margin: 2,
  },
  container: {
    margin: 4,
  },
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  paper: {
    padding: 8,
    margin: "auto",
    textAlign: "center",
    maxWidth: 600,
  },

  paperContent: {
    margin: "20",
    padding: 8,
    minHeight: "30vh",
    textAlign: "center",
    width: "90vw",
  },
  ecgChart: {
    maxWidth: "400px",
  },
  ecgBlock: {
    padding: 8,
    maxWidth: "600px",
  },
  modal: {
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
  modalbottom: {
    position: "absolute",
    top: "80%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    "margin-top": "25px",
  },
  modalPaper: {
    position: "absolute",
    width: 400,
    height: 400,
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    padding: "8 16 12",
    left: "50%",
    top: "50%",
    transform: `translate(-50%, -50%)`,
  },

  smallTextField: {
    width: "12ch",
  },
  textField: {
    width: "25ch",
  },
  uuidtextField: {
    width: "37ch",
  },
  endpointTextField: {
    width: "50ch",
  },

  mapViewScale: {
    position: "absolute",
    bottom: 100,
  },
  mapButton: {
    maxWidth: "25vh",
    left: 0,
    top: 1,
    margin: "auto",
  },
  fileInput: {
    display: "none",
  },
  hide: {
    display: "None",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  Content: {
    marginTop: 50,
  },
  imageAvatar: {
    width: 75,
    height: 75,
  },
}));

export default styles;
