import { createTheme } from "@mui/material/styles";
import blue from "@mui/material/colors/blue";
import grey from "@mui/material/colors/grey";

const custom_theme = createTheme({
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    secondary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    text: {
      main: grey[500],
    },
  },
});

export default custom_theme;
