import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Button, Box, Input } from "../Components/";
import Fade from "@mui/material/Fade";

import Alert from "../Components/alert";
import Snackbar from "@mui/material/Snackbar";
import Visibility from "@mui/icons-material/Visibility";
import packageJson from "../../package.json";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Grid,
  CircularProgress,
  Typography,
  TextField,
  IconButton,
  InputLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Stack,
} from "@mui/material";

import { styles } from "../Components";

const SignIn = ({ onAuthComplete, isAuth }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [newUser, setNewUser] = useState({});
  let navigate = useNavigate();

  const classes = styles();

  const signIn = async () => {
    setIsLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        setNewUser(user);
        setShowPasswordChange(true);
      } else if (!!user) {
        onAuthComplete();
        navigate("/Devices");
      }
    } catch (e) {
      setErrorOpen(true);
      setError(e.message);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const updatePassword = async () => {
    setIsLoading(true);
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      setErrorOpen(true);
      setIsLoading(false);
      return;
    }
    try {
      const user = await Auth.completeNewPassword(newUser, password);
      onAuthComplete();
      navigate("/Devices");
    } catch (e) {
      setErrorOpen(true);
      setError(e.message);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAlertClose = () => {
    setErrorOpen(false);
    setError("");
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const validatePassword = () => {
    if (!!newPassword && !!confirmPassword) {
      if (newPassword !== confirmPassword) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit>
        <div>
          <>
            <Box sx={{ p: 10 }} />
            <Grid
              spacing={2}
              justifyContent="center"
              alignItems="center"
              container
            >
              <Stack sx={{ width: "360" }} spacing={2}>
                {errorOpen && (
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={errorOpen}
                    onClose={handleAlertClose}
                    key={"snackbar-login-message"}
                  >
                    <Alert onClose={handleAlertClose} severity="error">
                      {error}
                    </Alert>
                  </Snackbar>
                )}
              </Stack>
              <Grid item xs={12} justifyContent="center" alignContent="center">
                <Paper className={classes.paper} elevation={5}>
                  <Box sx={{ p: 2 }} />
                  <img width="280" src="./caremate_logo.png" />
                  {showPasswordChange && (
                    <form
                      className={classes.root}
                      noValidate
                      autoComplete="off"
                      error={error}
                    >
                      <Box>
                        <Input
                          value={newPassword}
                          onChange={(event) =>
                            setNewPassword(event.target.value)
                          }
                          label="New Password"
                          error={validatePassword()}
                        ></Input>
                      </Box>
                      <Box>
                        <Input
                          value={confirmPassword}
                          error={validatePassword()}
                          onChange={(event) =>
                            setConfirmPassword(event.target.value)
                          }
                          label="Confirm Password"
                        ></Input>
                      </Box>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        container
                        spacing={2}
                      >
                        <Box>
                          <Button
                            disabled={!newPassword || !confirmPassword}
                            onClick={() => updatePassword()}
                            sx={{ width: "auto" }}
                          >
                            Change Password
                          </Button>
                        </Box>
                      </Stack>
                    </form>
                  )}
                  {!showPasswordChange && (
                    <form
                      className={classes.root}
                      noValidate
                      autoComplete="off"
                      error={error}
                    >
                      <Box sx={{ p: 2 }}>
                        <TextField
                          className={classes.textField}
                          id="username"
                          label="Username"
                          variant="outlined"
                          error={errorOpen}
                          value={username}
                          onChange={(event) => setUsername(event.target.value)}
                        />
                      </Box>
                      <Box sx={{ p: 2 }}>
                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                        >
                          <InputLabel htmlFor="password-input">
                            Password
                          </InputLabel>
                          <OutlinedInput
                            id="password-input"
                            label="Password"
                            autoComplete={"off"}
                            error={errorOpen}
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(event) => {
                              setPassword(event.target.value);
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleShowPassword}
                                  onMouseDown={handleMouseDown}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          ></OutlinedInput>
                        </FormControl>
                      </Box>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        container
                        spacing={2}
                      >
                        <Box sx={{ width: 120, textAlign: "center" }}>
                          <Button
                            disabled={!username || !password}
                            onClick={() => signIn()}
                            fullWidth
                          >
                            Login
                          </Button>
                        </Box>

                        <Box sx={{ width: 120 }}>
                          <Button fullWidth variant="text">
                            Forget Password
                          </Button>
                        </Box>
                      </Stack>
                    </form>
                  )}
                </Paper>
              </Grid>
              {isLoading && (
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  alignContent={"center"}
                  xs={12}
                >
                  <CircularProgress size={100} />
                </Grid>
              )}
              <Box sx={{ position: "absolute", bottom: 10 }}>
                <Typography>{`version: ${packageJson.version}`}</Typography>
              </Box>
            </Grid>
          </>
        </div>
      </Fade>
    </>
  );
};

export default SignIn;
