import React from "react";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import { Fade, Typography, Grid, Paper, Table, TableBody } from "@mui/material";

import { Button, styles } from "../Components";

const patches = Array.from(Array(5).keys());

const InventoryTable = ({
  devices,
  isLoading,
  selectedProvider,
  updateProvider,
}) => {
  const classes = styles();

  const getSkeletonFromEntry = (rowNum) => {
    return (
      <TableRow key={`skeleton-row-${rowNum}`}>
        <TableCell align="left" width={150}>
          <Skeleton />
        </TableCell>
        <TableCell align="left" width={150}>
          <Skeleton />
        </TableCell>
        {!!updateProvider && (
          <TableCell align="left" width={150}>
            <Skeleton />
          </TableCell>
        )}
        <TableCell align="right" width={80}>
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  };

  const getTableFromEntry = (entry) => {
    return (
      <TableRow key={`patch-row-${entry.Index}`}>
        <TableCell width={150} align="left">
          {entry.DeviceID}
        </TableCell>
        <TableCell width={150} align="left">
          {entry?.ProdSerNum}
        </TableCell>

        {!!updateProvider && (
          <TableCell width={150} align="left">
            <Button
              variant="text"
              disabled={!entry?.ProdSerNum}
              onClick={() => {
                updateProvider(entry.ProdSerNum);
              }}
            >
              update
            </Button>
          </TableCell>
        )}
        <TableCell align="right">{entry.ProvID}</TableCell>
      </TableRow>
    );
  };
  return (
    <Grid item xs={12} container justifyContent="center">
      {isLoading && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1020 }} className={classes.table}>
              <TableHead key="thead">
                <TableRow key="tr-head">
                  <TableCell align="left" width={150}>
                    Device ID
                  </TableCell>
                  <TableCell align="left" width={150}>
                    ProdSerNum
                  </TableCell>
                  {!!updateProvider && (
                    <TableCell align="left" width={150}>
                      Update Provider
                    </TableCell>
                  )}
                  <TableCell align="right" width={80}>
                    Provider ID
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patches.map((e) => getSkeletonFromEntry(e))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {!isLoading && !!devices && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1020 }} className={classes.table}>
              <TableHead key="thead">
                <TableRow key="tr-head">
                  <TableCell align="left" width={150}>
                    Device ID
                  </TableCell>
                  <TableCell align="left" width={150}>
                    ProdSerNum
                  </TableCell>
                  {!!updateProvider && (
                    <TableCell align="left" width={130}>
                      Update Provider
                    </TableCell>
                  )}
                  <TableCell align="right" width={80}>
                    Provider ID
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{devices.map((e) => getTableFromEntry(e))}</TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Grid>
  );
};

export default InventoryTable;
