import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { BrowserRouter as Router, Route, Navigate } from "react-router-dom";

const PrivateRoute = ({ children, path, isAuth }) => {
  return (
    <Route
      path={path}
      render={() => {
        return isAuth ? (
          { children }
        ) : (
          <Navigate to="/login" state={{ path }} replace />
        );
      }}
    />
  );
};

export default PrivateRoute;
