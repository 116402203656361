import { Skeleton, TextField as MyInput } from "@mui/material";

const Input = ({ isLoading, children, select, ...rest }) => {
  return isLoading ? (
    <Skeleton
      variant="rectangular"
      sx={{
        borderRadius: "4px",
      }}
    >
      <MyInput sx={{ width: "25ch" }} select={select} {...rest}>
        {children}
      </MyInput>
    </Skeleton>
  ) : (
    <MyInput sx={{ width: "25ch" }} select={select} {...rest}>
      {children}
    </MyInput>
  );
};

export default Input;
