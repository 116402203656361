import React, { useState } from "react";
import Input from "../Components/input";
import Box from "../Components/box";
import MenuItem from "@mui/material/MenuItem";

import styles from "../Components/styles";

import { listTimeZones } from "timezone-support";

const TimeZoneSelect = ({ onSelect }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    "America/Los_Angeles"
  );
  const classes = styles();

  const handleSelect = (event) => {
    setSelectedTimezone(event.target.value);
    onSelect(event.target.value);
  };
  return (
    <Box>
      <Input
        select
        className={classes.textField}
        labelid="dropdown-select-tz-label"
        id="dropdown-select-tz"
        label="TimeZone"
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>America/Los_Angeles</em>;
          }
          return selectedTimezone;
        }}
        value={selectedTimezone}
        onChange={handleSelect}
      >
        {listTimeZones().map((option, index) => {
          return (
            <MenuItem key={`dropdown-${index}-${option}`} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Input>
    </Box>
  );
};

export default TimeZoneSelect;
